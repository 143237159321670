<template>
  <div>
    <div class="dp-flex">
      <div class="mr-auto">
        <h6 class="title" @click="OpenList">
          <a>클라우드PC 신청 <i class="icon-fux-page-next"></i></a>
        </h6>
      </div>
      <div class="ml-auto">
        <button class="btn-round-l m-r-xl" @click="OpenPolicy">
          <i class="icon-fux-edit"></i>신규신청
        </button>
      </div>
    </div>
    <div class="space-s"></div>
    <div class="grd-wrap">
      <table width="100%" class="grd">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col style="width: 130px" />
        </colgroup>
        <thead>
          <tr>
            <th>사용시작일</th>
            <th>사용종료일</th>
            <th>신청일</th>
            <th>현재 상태</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="applyInfo.length != 0 && doneList == true">
          <tr v-for="apply in applyInfo" :key="apply._id">
            <td class="align-center">
              {{ apply.startTime.replace("T", " ").slice(0, -3) }}
            </td>
            <td class="align-center">
              {{ apply.endTime.replace("T", " ").slice(0, -3) }}
            </td>
            <td class="align-center">
              {{ apply.applyTime.replace("T", " ").slice(0, -7) }}
            </td>
            <td v-if="apply.returnTime != null" class="align-center">반납</td>
            <td v-else-if="apply.isActive == false" class="align-center">취소</td>
            <td v-else-if="apply.isExpire == true" class="align-center">기간만료</td>
            <td v-else-if="apply._id == userApplyId" class="align-center">
              <span v-if="activeComplete == true" class="ft-green ft-700">사용가능</span>
              <span v-else class="ft-orange ft-700">준비중</span>
            </td>
            <td v-else class="align-center">미도래</td>
            <td class="align-center">
              <button v-if="apply.returnTime == null && apply.isExpire == false && apply.isActive == true && (applyCancelBtn == true || apply.isFurture)" @click="OpenCancel(apply)" class="btn-round-s">
                신청취소
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-if="applyInfo.length == 0 && doneList == true">
          <tr>
            <td class="align-center" :colspan="5">신청 이력이 없습니다.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="isListModal" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">신청 이력 조회</h4>
              </div>
              <div class="ml-auto">
                <a class="close-btn" @click="CloseList">
                  <i class="icon-fux-close1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="modal-size optional-modal-padding" style="width: 700px">
              <div class="around-scroll-grd" style="max-height: 280px">
                <table width="100%" class="grd">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col style="width: 130px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>사용시작일</th>
                      <th>사용종료일</th>
                      <th>신청일</th>
                      <th>현재 상태</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="applyAllInfo.length != 0 && doneList == true">
                    <tr v-for="apply in applyAllInfo" :key="apply._id">
                      <td class="align-center">
                        {{ apply.startTime.replace("T", " ").slice(0, -3) }}
                      </td>
                      <td class="align-center">
                        {{ apply.endTime.replace("T", " ").slice(0, -3) }}
                      </td>
                      <td class="align-center">
                        {{ apply.applyTime.replace("T", " ").slice(0, -7) }}
                      </td>
                      <td v-if="apply.returnTime != null" class="align-center">반납</td>
                      <td v-else-if="apply.isActive == false" class="align-center">취소</td>
                      <td v-else-if="apply.isExpire == true" class="align-center">기간만료</td>
                      <td v-else-if="apply._id == userApplyId" class="align-center">
                        <span v-if="activeComplete == true" class="ft-green ft-700">사용가능</span>
                        <span v-else class="ft-orange ft-700">준비중</span>
                      </td>
                      <td v-else class="align-center">미도래</td>
                      <td class="align-center">
                        <button v-if="apply.returnTime == null && apply.isExpire == false && apply.isActive == true && (applyCancelBtn == true || apply.isFurture)" @click="OpenCancel(apply)" class="btn-round-s">
                          신청취소
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="applyAllInfo.length == 0 && doneList == true">
                    <tr>
                      <td class="align-center" :colspan="5">
                        신청 이력이 없습니다.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <div class="align-center">
              <a class="btn" @click="CloseList">닫기</a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="isPolicyModal" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">클라우드PC 준법사용안내</h4>
              </div>
              <div class="ml-auto">
                <a class="close-btn" @click="ClosePolicy">
                  <i class="icon-fux-close1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="modal-size optional-modal-padding" style="width: 700px">
              본인은 신한금융지주회사(이하, “회사”라 함.)에서 제공하는
              클라우드PC를 사용함에 있어 다음의 보안사항을 준수할 것을
              동의·서약합니다.<br /><br />
              1. 본인은 클라우드PC를 업무 목적으로만 사용한다.<br />
              2. 본인은 클라우드PC 인증과 관련된 정보나 매체를 타인에게
              유출하거나 공유하지 아니한다.<br />
              3. 본인은 내부 자료나 중요 개인정보 포함 자료 등을 외부에 열람,
              전송, 공유하지 아니한다.<br />
              4. 본인은 클라우드PC 화면을 촬영하거나 캡쳐하여 별도 저장하거나
              외부에 유출, 공유하지 아니한다.<br />
              5. 본인은 클라우드PC 접속용 PC는 백신 등 보안 프로그램을 최신으로
              유지한다.<br />
              6. 본인은 보안 수준을 확인할 수 없는 장소(공공장소, 카페 등)에서
              클라우드PC에 접속하지 않는다.<br />
              7. 본인은 보안 이슈나 특이사항을 발견한 경우 지체 없이
              정보보호 담당자에게 연락해 조치를 받는다.<br />
              8. 본인은 본 서약서에서 언급하지 않은 여타 보안사항(관련 법령,
              회사 보안규정, 가이드라인 등)을 성실히 준수하며, 위반 시 관련
              법령과 회사의 규정에 따른 책임 이행을 동의한다.<br />
              <!-- 클라우드PC 는 신한금융지주회사 임직원을 위한 시스템으로
              <br />불법으로 사용시에는 법적 제재가 있을 수 있습니다. -->
              <div class="space-l"></div>
              <div class="align-center">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="isPolicyChecked" />
                    &nbsp;&nbsp;&nbsp;<b class="ft-l">위의 내용에 동의합니다.</b>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="align-center">
              <a class="btn" @click="OpenApply">확인</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isApplyModal" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">클라우드PC 신청</h4>
              </div>
              <div class="ml-auto">
                <a class="close-btn" @click="CloseApply">
                  <i class="icon-fux-close1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="modal-size optional-modal-padding" style="width: 500px">
              클라우드PC 를 사용하실 기간을 입력해 주세요.
              <div class="space-s"></div>
              <div class="box-blue p-all-l">
                <div class="date-wrap m-l-l">
                  <div class="date-input">
                    <VueDatePicker
                      style="width: 180px"
                      v-model="startDate"
                      :format="DateFormat(startDate)"
                      :teleport="true"
                      @date-update="ChangeStartDate"
                    >
                      <template #time-picker="{ time, updateTime }">
                        <div
                          class="custom-time-picker-component"
                          style="text-align: center"
                        >
                          <select
                            class="select-input"
                            :value="time.hours"
                            @change="updateTime(+$event.target.value)"
                          >
                            <option
                              v-for="h in hoursArray()"
                              :key="h.value"
                              :value="h.value"
                            >
                              {{ h.text }}
                            </option>
                          </select>
                          :
                          <select
                            class="select-input"
                            :value="time.minutes"
                            @change="updateTime(+$event.target.value, false)"
                          >
                            <option
                              v-for="m in minutesArray()"
                              :key="m.value"
                              :value="m.value"
                            >
                              {{ m.text }}
                            </option>
                          </select>
                        </div>
                      </template>
                    </VueDatePicker>
                  </div>
                  ~
                  <div class="date-input">
                    <VueDatePicker
                      style="width: 180px"
                      v-model="endDate"
                      :format="DateFormat(endDate)"
                      :teleport="true"
                    >
                      <template #time-picker="{ time, updateTime }">
                        <div
                          class="custom-time-picker-component"
                          style="text-align: center"
                        >
                          <select
                            class="select-input"
                            :value="time.hours"
                            @change="updateTime(+$event.target.value)"
                          >
                            <option
                              v-for="h in hoursArray()"
                              :key="h.value"
                              :value="h.value"
                            >
                              {{ h.text }}
                            </option>
                          </select>
                          :
                          <select
                            class="select-input"
                            :value="time.minutes"
                            @change="updateTime(+$event.target.value, false)"
                          >
                            <option
                              v-for="m in minutesArray()"
                              :key="m.value"
                              :value="m.value"
                            >
                              {{ m.text }}
                            </option>
                          </select>
                        </div>
                      </template>
                    </VueDatePicker>
                  </div>
                </div>
              </div>
              <div class="space-s"></div>
              <div class="align-center">
                <span class="fm-validation-error" v-html="warnMsg"></span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="align-center">
              <a class="btn" @click="CloseApply">닫기</a>
              <span class="btn-bdr"></span>
              <a class="btn" @click="SetUserApply">신청</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCancelModal" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">클라우드PC 신청취소</h4>
              </div>
              <div class="ml-auto">
                <a class="close-btn" @click="CloseCancel">
                  <i class="icon-fux-close1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="modal-size optional-modal-padding" style="width: 500px">
              신청을 취소하시겠습니까?
            </div>
          </div>
          <div class="modal-footer">
            <div class="align-center">
              <a class="btn" @click="CloseCancel">닫기</a>
              <span class="btn-bdr"></span>
              <a class="btn" @click="CancelApply(false)">신청취소</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDeleteModal == true" id="modal-return" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">클라우드PC 신청취소</h4>
              </div>
              <div class="ml-auto">
                <a class="close-btn" @click="CloseDelete">
                  <i class="icon-fux-close1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="modal-size optional-modal-padding" style="width: 600px">
              클라우드PC 를 사용신청을 취소하시면 클라우드PC 내부에 저장되었던 자료는 모두 삭제됩니다.
              <br />클라우드PC 를 사용신청을 취소하시겠습니까?
            </div>
          </div>
          <div class="modal-footer">
            <div class="align-center">
              <a class="btn" @click="CloseDelete">닫기</a>
              <span class="btn-bdr"></span>
              <a class="btn" @click="CancelApply(true)">신청취소</a>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  props: ['userApplyId'],
  data() {
    return {
      doneList: false,

      employeeNo: sessionStorage.getItem("loginUser"),
      applyInfo: [],
      applyAllInfo: [],
      currentApply: null,

      isListModal: false,
      isPolicyModal: false,
      isPolicyChecked: false,
      isApplyModal: false,
      isCancelModal: false,
      isDeleteModal: false,
      activeComplete: false,
      applyCancelBtn: false,
      applyCancelBtn2: false,

      startDate: new Date(),
      endDate: new Date(),
      serverApiRoot: sessionStorage.getItem("serverApiRoot"),
      isChangeDate: false,
      warnMsg: null,
    };
  },
  mounted() {
    this.GetUserApplyList();
    this.SetDefaultDate();
  },
  methods: {
    GetUserApplyList() {
      const data = {
        limit: 0,
      };

      this.$axios
        .post(`${this.serverApiRoot}/getUserApplyList/`, data, { withCredentials: true })
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            this.applyAllInfo = response.data;
            let maxCnt = this.applyAllInfo.length >= 4 ? 4 : this.applyAllInfo.length;
            for (let i = 0; i <= maxCnt - 1; i++) {
              let dict = this.applyAllInfo[i];
              dict['isFurture'] = dict.returnTime == null && dict.isActive == true && dict.isExpire == false && dict._id != this.userApplyId;
              this.applyInfo.push(dict);
            }
            this.doneList = true;
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    OpenList() {
      this.isListModal = true;
    },
    CloseList() {
      this.isListModal = false;
    },
    OpenPolicy() {
      this.isPolicyModal = true;
      this.$emit('StartAuthTimer')
    },
    ClosePolicy() {
      this.isPolicyModal = false;
      this.isPolicyChecked = false;
    },
    SetDefaultDate() {
      this.startDate = new Date();
      this.endDate = new Date();
      this.endDate.setDate(this.endDate.getDate());
      this.endDate.setHours(23);
      this.endDate.setMinutes(59);
      this.isChangeDate = false;
      this.warnMsg = null;
    },
    ChangeStartDate(e) {
      if (e) {
        this.isChangeDate = true;
      }
      // if (this.isChangeDate == false) {
      //   this.endDate.setDate(e.getDate());
      //   this.endDate.setHours(23);
      //   this.endDate.setMinutes(59);
      //   this.isChangeDate = true;
      // }
    },
    OpenApply() {
      if (this.isPolicyChecked) {
        this.ClosePolicy();
        this.isApplyModal = true;
      } else {
        alert("동의에 체크해주십시오.");
      }
    },
    CloseApply() {
      this.SetDefaultDate();
      this.isApplyModal = false;
    },
    OpenCancel(apply) {
      this.currentApply = apply;

      const data = {
        id: this.currentApply._id,
      };

      this.$axios
        .post(`${this.serverApiRoot}/validUserAuthLog/`, data, { withCredentials: true })
        .then((response) => {
          if (response.data.isNotYet == false) {
            if (response.data.list.length > 0) {
              this.isCancelModal = true;
            } else {
              this.isDeleteModal = true;
            }
          } else {
            this.isCancelModal = true;
          }
        })
        .catch((error) => {
          alert(error);
        });      
    },
    CloseCancel() {
      this.currentApply = null;
      this.isCancelModal = false;
    },
    CloseDelete() {
      this.currentApply = null;
      this.isDeleteModal = false;
    },   
    SetUserApply() {
      if (this.startDate == null) {
        this.warnMsg = "사용 시작일시를 선택하세요."
        return;
      }

      if (this.endDate == null) {
        this.warnMsg = "사용 종료일시를 선택하세요."
        return;
      }

      if (this.startDate >= this.endDate) {
        this.warnMsg = "종료일시를 시작일시 이후로 선택하세요."
        return;
      }

      const data = {
        startTime: this.DateFormat(this.startDate),
        endTime: this.DateFormat(this.endDate),
      };

      this.$axios
        .post(`${this.serverApiRoot}/setUserApply/`, data, { withCredentials: true })
        .then((response) => {
          if (response.data.id) {
            // this.$router.go(0);
            this.RequestMessage(response.data.id);
          } else {
            if (response.data.msgCode == "A01") {
              this.warnMsg = "중복 신청된 기간이 있습니다.";
            } else if (response.data.msgCode == "A02") {
              this.warnMsg = "신청기간의 클라우드PC 전체 신청수가 초과했습니다.<br>신청 기간을 변경하여 다시 시도해보십시오.";
            } else if (response.data.msgCode == "A03") {
              this.warnMsg = "7일을 초과하는 기간은 신청할 수 없습니다.";
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    RequestMessage(applyId) {
      const data = {
        applyId: applyId,
      };

      this.$axios
        .post(`${this.serverApiRoot}/request_message_send/`, data, { withCredentials: true })
        .then((response) => {
          if (response.data.data.msgProcessId) {
            this.$router.go(0);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    DateFormat(date) {
      let result = null;
      if (date != null) {
        result = format(date, "yyyy-MM-dd HH:mm");
      }
      return result;
    },
    CancelApply(withVmDelete) {
      const data = {
        id: this.currentApply._id,
      };
      this.$axios
        .post(`${this.serverApiRoot}/cancelUserApply/`, data, { withCredentials: true })
        .then((response) => {
          if (response.data.id) {
            this.CloseCancel();
            if (withVmDelete == true) {
              this.DeleteVm();
            }
            alert("사용신청이 취소되었습니다.");
            this.$router.go(0);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    DeleteVm() {
      this.$axios
        .post(`${this.serverApiRoot}/deleteVm/`, {}, { withCredentials: true })
        .then(() => {
        })
        .catch((error) => {
          alert(error);
        });
    },  
    ChangeApplyStatus() {
      this.activeComplete = true;
    },
    ControlApplyButton(flag) {
      this.applyCancelBtn = flag;
    },    
    hoursArray() {
      const arr = [];
      for (let i = 0; i < 24; i++) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
      }
      return arr;
    },
    minutesArray() {
      const arr = [];
      for (let i = 0; i < 60; i++) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
      }
      return arr;
    },
  },
};
</script>
