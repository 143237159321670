<template>
    <div class="notice-wrap">
        <div class="dp-flex">
            <div class="mr-auto">
                <h6 class="title" @click="AllAnnouncementsList"><a>공지사항 <i class="icon-fux-page-next"></i></a></h6>
            </div>
        </div>
        <div class="space-m"></div>
        <div class="grd-wrap">
            <div class="notice-list" v-if="doneList">
                <ul v-if="announcements.length != 0">
                    <li v-for="announce in announcements" :key="announce._id">
                        <a @click="CallView(announce);">
                            {{announce.title}}
                        </a>
                        <div class="info dp-flex">
                            <p class="date mr-auto">{{announce.createDate.split('T')[0]}}</p>
                            <span class="ml-auto new m-b-s" v-show="announce.isNew == true">N</span>
                        </div>
                    </li>
                </ul>
                <ul v-else>
                   <li> 공지사항이 없습니다.</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        doneList: false,
        announcements: [],
        serverApiRoot: sessionStorage.getItem("serverApiRoot"),
    };
  },
  mounted() {
    this.GetAnnouncementsList();
  },
  methods: {
      GetAnnouncementsList () {
          const data = {
              limit: 4
          }
          this.$axios
              .post(`${this.serverApiRoot}/getAnnouncementsList/`, data, { withCredentials: true })
              .then((response) => {
                  if (response.status == 200 && response.data != null) {
                      this.announcements = response.data;
                      this.doneList = true;
                  }
              })
              .catch((error) => {
                  alert(error);
              });
      },
      AllAnnouncementsList () {
        window.open("/Notice");
      },
       CallView (announce) {
            window.open(`/NoticeItem?id=${announce._id}`);
      }

  }
};
</script>
