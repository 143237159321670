<template>
  <div>
    <div id="wrapper-login">
      <div class="container-login">
        <div class="login-wrap">
          <div class="login-header">
            <h1 class="logo">
              <img src="../assets/images/logo_blue.png" alt="신한금융지주회사" />
              <span>클라우드PC 로그인</span>
            </h1>
            <p class="info">
              클라우드PC 는 주 사업장(사무실) 이용불가 상황에서도
              <br />업무연속성(BCP)을 제공하기 위해 <span>클라우드 환경에 구성한 가상 업무PC</span>입니다.
            </p>
            <div class="space-30"></div>
          </div>
          <div class="login-form">
            <div
              v-if="currentForm == 'PASSWORD' && isPassOwnedMedia == false"
              id="form01"
              class="form-inner"
            >
              <div class="space-30"></div>
              <div class="input-wp">
                <p>사번</p>
                <input
                  type="text"
                  v-model="userId2"
                  title="id"
                  placeholder="사번을 입력하세요."
                  @keyup.enter="GetUserInfoItem(false)"
                />
              </div>
              <div class="space-l"></div>
              <div class="input-wp">
                <p>비밀번호</p>
                <input
                  type="password"
                  v-model="pwd"
                  title="password"
                  placeholder="Swing 비밀번호를 입력하세요"
                  @keyup.enter="GetUserInfoItem(false)"
                />
              </div>
              <div class="space-30"></div>
              <button class="login-btn" @click="GetUserInfoItem(false)">
                로그인
              </button>
              <div class="space-25"></div>
              <div class="login-link">
                Swing 패스워드를 입력하세요. 클라우드PC 포털은<br />
                암호 유출 방지를 위해
                <a @click="ChangeForm('MOBILE')">Swing 모바일 로그인</a>을
                권고합니다.
              </div>
            </div>
            <div
              v-if="currentForm == 'MOBILE' && isPassOwnedMedia == false"
              id="form02"
              class="form-inner"
            >
              <div class="space-50"></div>
              <div class="input-wp">
                <p>사번</p>
                <input
                  type="text"
                  v-model="userId"
                  title="id"
                  placeholder="사번을 입력하세요."
                  @keyup.enter="GetUserInfoItem(true)"
                />
              </div>
              <div class="space-xl"></div>
              <button class="login-btn" @click="GetUserInfoItem(true)">
                Swing 모바일 로그인
              </button>
              <div class="space-25"></div>
              <div class="login-link">
                암호 유출 방지를 위해 Swing 모바일 로그인을 권고합니다.<br />
                Swing 앱이 설치되지 않은 경우
                <a @click="ChangeForm('PASSWORD')">패스워드 로그인</a>도
                가능합니다.
              </div>
            </div>
            <div v-if="isPassOwnedMedia == true" id="form03" class="form-inner">
              <div class="space-50"></div>
              <p class="text">
                Swing 모바일 앱으로 인증 요청을 보냈습니다.<br />
                3분 이내에 완료 후 버튼을 눌러 로그인 하세요.
              </p>
              <div class="space-40"></div>
              <p v-if="!authEnd" class="time">잔여시간 {{ authTimer }}</p>
              <p v-else class="time step-process-txt ft-red">잔여시간 {{ authTimer }}</p>
              <div class="space-40"></div>
              <button v-if="!authEnd" class="login-btn" @click="CallCheck">
                인증 완료
              </button>
              <div v-else class="step-process-txt ft-red align-center">
                SWING 모바일 앱 인증 시간이 초과되었습니다.<br/>
                다시 로그인을 시도해주십시오.
              </div>
              <div class="space-l"></div>
              <p class="text"><a @click="this.$router.go(0)" class="ft-400">로그인 페이지로 돌아가기</a></p>
            </div>
          </div>
          <div class="login-footer">
            <div>
              <i class="icon-fux-notice"></i>
              <a v-if="announcements" @click="CallView()">{{ announcements.title }}</a>
            </div>
            <div>
              <i class="icon-fux-call"></i>
              접속문의
              <span class="number">02.2010.1269</span>
            </div>
          </div>
        </div>
        <div id="footer">
          <div class="footer-container">
            <div class="copyright">
              <img src="../assets/images/logo_gray.png" alt="신한금융지주회사" />
              <div class="text">
                클라우드 PC 포털은 임직원을 위한 시스템으로 불법으로 사용시에는
                법적 제제가 있을 수 있습니다.
                <p class="en">
                  COPYRIGHT 2023 BY SHINHAN FINANCIAL GROUP ALL RIGHT RESERVED.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isNoticeModal" id="notice" class="modal">
        <div class="modal-flex">
            <div class="modal-flex-inner">
                <div class="modal-header">
                    <div class="dp-flex">
                        <div class="mr-auto">
                            <h4 class="title">공지사항</h4>
                        </div>
                        <div class="ml-auto">
                            <a class="close-btn" @click="CloseNotice">
                                <i class="icon-fux-close1"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-content">
                    <!--설명 : 모달의 너비와 높이는 modal-size 태그에서 조절합니다.-->
                    <div class="modal-size optional-modal-padding" style="width: 800px;">
                        <div class="modal-area bg-gray">
                            <div class="area-title">
                                <h6>{{ announcements.title }}</h6>
                            </div>
                            <div class="area-content" v-html="announcements.content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>       
  </div>

</template>

<script>
export default {
  data() {
    return {
      userId: null,
      userId2: null,
      pwd: null,
      isPassOwnedMedia: false,
      passUserId: null,
      eventId: null,
      methodType: "PUSH_SWING_PORTAL",
      serverApiRoot: `${window.location.protocol}//${window.location.hostname}`,
      currentForm: "MOBILE",
      authTimer: "03:00",
      timerSeconds: 180,
      authEnd: false,
      announcements: null,
      isNoticeModal: false,
    };
  },
  mounted() {
    const apiUrl = {
        "https://cloudpc.shinhangroup.com": "https://view.cloudpc.shinhangroup.com",
        "https://cpc.shinhangroup.com": "https://view.cpc.shinhangroup.com",
        "http://localhost": "http://localhost:8083",
        "http://10.243.244.133": "http://10.243.244.133:8083",
    };
    this.serverApiRoot = apiUrl[this.serverApiRoot];
    this.GetPopupAnnouncementsItem();
  },
  beforeUnmount() {
    clearInterval(this.authTimerInterval);
  },
  methods: {
    StartAuthTimer() {
      this.authTimerInterval = setInterval(() => {
        this.timerSeconds--;
        if (this.timerSeconds >= 0) {
          this.authTimer = this.FormatTime(this.timerSeconds);
        } else {
          clearInterval(this.authTimerInterval);
          this.authTimer = "00:00";
          this.authEnd = true;
        }
      }, 1000);
    },
    FormatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const minutesString = String(minutes).padStart(2, "0");
      const secondsString = String(remainingSeconds).padStart(2, "0");
      return `${minutesString}:${secondsString}`;
    },
    ChangeForm(type) {
      this.userId = null;
      this.userId2 = null;
      this.pwd = null;
      this.currentForm = type;
    },
    GetUserInfoItem(isMobile) {
      if (!this.userId && isMobile) {
        alert("사번을 입력하십시오.");
        return;
      }

      if (!this.userId2 && !isMobile) {
        alert("사번을 입력하십시오.");
        return;
      }

      if (!this.pwd && !isMobile) {
        alert("패스워드를 입력하십시오.");
        return;
      }

      const data = {
        employeeNo: isMobile ? this.userId : this.userId2,
      };

      this.$axios
        .post(`${this.serverApiRoot}/getUserInfoItem/`, data)
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            if (response.data.isActive == true) {
              if (isMobile) {
                this.CallLogin(response.data.employeeNo);
              } else {
                this.CallLoginPw(response.data.employeeNo, this.pwd);
              }              
            } else {
              alert("Cloud PC 사용 제한된 사용자입니다.");
            }        
          } else {
            alert("클라우드PC 포털 시스템에 등록된 계정이 아닙니다.");
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    CallLogin(employeeNo) {
      const data = {
        userId: employeeNo,
        authRequestMethod: this.methodType,
      };

      this.$axios
        .post(`${this.serverApiRoot}/authorize_ownedmedia/`, data)
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            if (response.data.common.resultCode == 200) {
              if (response.data.data.authEventId) {
                this.isPassOwnedMedia = true;
                this.passUserId = data.userId;
                this.eventId = response.data.data.authEventId;
                this.StartAuthTimer();
              } else {
                alert("인증 ID를 찾을 수 없습니다.");
              }
            } else {
              alert(response.data.common.errorMessage);
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    CallLoginPw(employeeNo, pwd) {
      const data = {
        userId: employeeNo,
        userPw: pwd,
      };

      this.$axios
        .post(`${this.serverApiRoot}/authorize_idpw/`, data)
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            const data = JSON.parse(response.data.swing);
            if (data.common.resultCode == 200) {
              if (data.data.authResult == "SUCCESS") {
                this.SetSession(employeeNo, response.data.auth);
                // this.SetUserAuthLog(employeeNo, "idpw");
                // this.$router.push({
                //   name: "/Main",
                //   params: {
                //     currentUserId: employeeNo,
                //     authSessionId: response.data.auth,
                //   },
                // });                
              } else {
                alert(data.data.authResultMessage);
              }
            } else {
              alert(data.common.errorMessage);
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    CallCheck() {
      const data = {
        userId: this.passUserId,
        eventId: this.eventId,
      };

      this.$axios
        .post(`${this.serverApiRoot}/check_auth_login/`, data)
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            const data = JSON.parse(response.data.swing);
            if (data.common.resultCode == 200) {
              clearInterval(this.authTimerInterval);            
              this.SetSession(this.passUserId, response.data.auth);
              // this.SetUserAuthLog(this.passUserId, "app");
              // this.$router.push({
              //   name: "/Main",
              //   params: {
              //     currentUserId: this.passUserId,
              //     authSessionId: response.data.auth,
              //   },
              // });                
            } else {
              alert(response.data.common.errorMessage);
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    GetPopupAnnouncementsItem() {
      this.$axios
        .post(`${this.serverApiRoot}/getPopupAnnouncementsItem/`, {})
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            this.announcements = response.data;
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    CallView() {
      this.isNoticeModal = true;
    },
    CloseNotice() {
      this.isNoticeModal = false;
    },
    SetSession(userId, authId) {
      const data = {
        userId: userId
      }

      this.$axios
         .post(`${this.serverApiRoot}/set_session/`, data, {
          withCredentials: true, 
        })
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            this.$router.push({
              name: "/Main",
              params: {
                currentUserId: userId,
                authSessionId: authId,
              },
            });
          } else {
            alert('세션 확인 바랍니다.');
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>
