<template>
    <div v-if="this.employeeNo" id="common-wrapper">
        <div id="wrapper">
            <div id="header">
                <div class="header-container">
                    <div class="header-item align-left">
                        <h1 class="logo">
                            <a href="/Main">
                                <img src="../assets/images/logo_blue.png" alt="신한금융지주회사">
                                <span>클라우드PC</span>
                            </a>
                        </h1>
                    </div>
                    <div class="header-item align-center">
                        <nav class="user-nav">
                            <ul>
                                <li class="on">
                                    <a>공지사항</a>
                                </li>
                                <li @click="$router.push('/FAQ')">
                                    <a>자주하시는 질문</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="header-item align-right"></div>
                </div>
            </div>
            <div id="outer-wrap">
                <div id="inner-wrap">
                    <div class="main-wrap">
                        <div class="user-header">
                            <h3 class="title">공지사항</h3>
                        </div>
                        <div class="user-body">
                            <div class="grd-wrap">
                                <table width="100%" class="notice-list">
                                    <tbody v-if="announcements === null || announcements.length">
                                        <tr v-for="announce in announcements" :key="announce._id">
                                            <td class="tit">
                                                <a @click="CallView(announce);">
                                                    {{announce.title}}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- footer -->
            <div id="footer">
                <div class="footer-container">
                    <div class="copyright">
                        <img src="../assets/images/logo_gray.png" alt="신한금융지주회사">
                        <div class="text">
                            클라우드 PC 포털은 임직원을 위한 시스템으로 불법으로 사용시에는 법적 제제가 있을 수 있습니다.
                            <p class="en">COPYRIGHT 2023 BY SHINHAN FINANCIAL GROUP ALL RIGHT RESERVED.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //footer -->
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        employeeNo: sessionStorage.getItem("loginUser"),
        announcements: null,
        serverApiRoot: sessionStorage.getItem("serverApiRoot"),
    };
  },
  mounted() {
    if (this.employeeNo == '' || this.employeeNo == null) {
        alert('잘못된 접근입니다.');
        return;
    } else {
        this.GetAnnouncementsList();
    } 
  },
  methods: {
      GetAnnouncementsList () {
          const data = {
              limit: 0
          }
          this.$axios
              .post(`${this.serverApiRoot}/getAnnouncementsList/`, data, { withCredentials: true })
              .then((response) => {
                  if (response.status == 200 && response.data != null) {
                      this.announcements = response.data;
                  }
              })
              .catch((error) => {
                  alert(error);
              });
      },
      CallView (announce) {
            window.location.href = `/NoticeItem?id=${announce._id}`;
      }
  }
};
</script>