<template>
  <div>
    <div id="common-wrapper">
      <div id="wrapper">
        <div id="header">
          <div class="header-container">
            <div class="header-item align-left">
              <h1 class="logo">
                <a href="/Main">
                  <img
                    src="../assets/images/logo_blue.png"
                    alt="신한금융지주회사"
                  />
                  <span>클라우드PC</span>
                </a>
              </h1>
            </div>
            <div class="header-item align-right">
              <ul class="item-content">
                <li>
                  <div v-if="userInfo" class="user-area">
                    <span class="name">{{ userInfo.userName }}</span
                    >({{ userInfo.employeeNo }})
                  </div>
                </li>
                <li v-if="authTimer">
                  <span style="font-size: 1rem;">자동 로그아웃 : {{ authTimer }}</span>
                </li>
                <li>
                  <a @click="Logout">
                    <i class="icon-fux-logout"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="outer-wrap">
          <div id="inner-wrap">
            <div class="main-wrap">
              <div class="space-50"></div>
              <div class="row">
                <div v-if="!isApproved" class="col-9">
                  <a class="step-wrap-none" @click="OpenPolicy">
                    <h3 class="ft-500">할당된 클라우드PC 가 없습니다.</h3>
                    <div class="space-m"></div>
                    <div class="ft-gray">
                      사용신청 버튼을 클릭하셔서 클라우드 PC 를 사용해 보세요
                    </div>
                    <div class="space-xl"></div>
                    <h5 class="ft-blue"><u>클라우드PC 신청</u></h5>
                  </a>
                </div>
                <div v-else class="col-9">
                  <div class="step-wrap">
                    <div class="dp-flex">
                      <div class="step">
                        <div class="step-title">VPN 연결</div>
                        <div class="step-info">
                          <span>클라우드PC에 접속하기 위해서는<br />VPN 연결이 선행 되어야 합니다.</span>
                        </div>
                        <div v-if="vpnStep != 2" class="space-30"></div>
                        <div class="dp-flex flex-column">
                          <div v-if="vpnStep == -1" class="step-process-txt ft-red">
                            VPN 계정 활성화에 실패했습니다.
                          </div>
                          <div v-if="vpnStep == 0">
                            <div>
                              <div class="lds-ellipsis">
                                  <div></div><div></div><div></div><div></div>
                              </div>
                              <div class="space-s"></div>
                            </div>                            
                            <div class="ft-lightgreen">VPN 을 사용가능한 상태로 전환 중입니다.</div>
                            <div v-if="vpnExpect != 0">
                              <div class="space-s"></div>
                              <div class="ft-yellow">예상시간 : {{ vpnExpect }}분</div>
                            </div>
                          </div>
                          <button v-if="vpnStep == 1" :disabled="vmStep == -1 || vmStatus != '활성'" @click="OpenSetVpnCheckModal" class="step-process-btn">
                            VPN 연결하기
                          </button>
                          <div v-if="vpnStep == 1" :disabled="vmStep == -1 || vmStatus != '활성'">
                            <div class="space-s"></div>
                            <span @click="CallVpnGuide" style="cursor: pointer; text-decoration: underline;">연결가이드</span>
                          </div>
                          <div v-if="vpnStep == 2" class="space-30"></div>
                          <div v-if="vpnStep == 2" class="step-complete">
                            <i class="icon-fux-check-mark1"></i>
                          </div>
                          <div v-if="vpnStep == 2">
                            <div class="space-m"></div>
                            <a style="color: #fff; text-decoration: underline;" @click="OpenSetVpnCheckModal">VPN 다시 연결하기</a>
                          </div>
                        </div>
                      </div>
                      <div class="step-arrow">
                        <i class="icon-fux-arrow-right-line-bold"></i>
                      </div>
                      <div class="step">
                        <div class="step-title">클라우드PC 접속</div>
                        <div class="step-info">
                          <span>"클라우드PC 접속하기"를 눌러<br />RDP 파일을 다운로드 후 실행하세요.</span>
                          <div class="space-m"></div>
                          <div class="dp-flex flex-around">
                            <div class="cloud" v-if="vmStep > 0">
                              I P<span v-if="userInfo">{{ userInfo.cloudIp }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="space-30"></div>
                        <div class="dp-flex flex-column">
                          <div v-if="vmStep == -2" class="step-process-txt ft-red">
                            클라우드PC 활성화에 실패했습니다.
                          </div>
                          <div v-if="vmStep == -1" class="step-process-txt ft-red">
                            클라우드PC 생성에 실패했습니다.
                          </div>
                          <div v-if="vmStep == 0">
                            <div>
                              <div class="lds-ellipsis">
                                  <div></div><div></div><div></div><div></div>
                              </div>
                              <div class="space-s"></div>
                            </div>                            
                            <div class="ft-lightgreen">클라우드PC를 준비 중입니다.</div>
                            <div v-if="vmExpect != 0">
                              <div class="space-s"></div>
                              <div class="ft-yellow">예상시간 : {{ vmExpect }}분</div>
                            </div>                                                        
                          </div>
                          <button v-if="vmStep == 1 || vmStep == 2" :disabled="vmStep != 2 || vmStatus != '활성'" @click="GetRdpFile" class="step-process-btn">
                            클라우드PC 접속하기
                          </button>
                          <div v-if="vmStep == 1 || vmStep == 2" :disabled="vmStep != 2 || vmStatus != '활성'" class="space-25"></div>                     
                          <!-- <button v-if="(vmStep == 1 || vmStep == 2) && (rdpSessionCount == null || rdpSessionCount == 0)" :disabled="vmStep != 2 || vmStatus != '활성'" @click="GetRdpFile" class="step-process-btn">
                            클라우드PC 접속하기
                          </button>
                          <div v-if="isCheckRdpSession == true && rdpSessionCount == null">
                            <div class="space-s"></div>
                            <span>접속여부 확인 중...(약 1분 소요)</span>
                          </div>
                          <div v-if="isCheckRdpSession == true && rdpSessionCount == 0" class="space-25"></div>
                          <div v-if="rdpSessionCount > 0" class="step-complete">
                            <i class="icon-fux-check-mark1"></i>
                          </div>
                          <div v-if="rdpSessionCount > 0">
                            <div class="space-m"></div>
                            <a style="color: #fff; text-decoration: underline;" @click="GetRdpFile">클라우드PC 다시 접속하기</a>
                          </div> -->
                        </div>                         
                      </div>
                      <div class="step-arrow">
                        <i class="icon-fux-arrow-right-line-bold"></i>
                      </div>
                      <div class="step">
                        <div class="step-title">클라우드PC 반납</div>
                        <div v-if="vmStep >= 1">
                          <div class="step-info">
                            클라우드 PC 사용이 완료되었다면 환경을 위해<br />Zero
                            Carbon 을 클릭해 주세요.
                          </div>
                          <div class="space-30"></div>
                          <div class="step-process">
                            <button @click="() => {isReturnModal = true; this.StartAuthTimer()}" :disabled="vmStatus != '활성'" class="step-process-btn">
                              Zero Carbon
                            </button>
                          </div>
                          <div class="space-25"></div>
                        </div>
                        <div
                          v-else
                          class="step-off"
                        >
                          <i class="icon-fux-cloud-off"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="guide-wrap">
                    <div class="dp-flex flex-column">
                      <img src="../assets/images/guide.png" />
                      <div class="space-xl"></div>
                      <h4>
                        <a @click="LoadPath('usermanual')" class="ft-blue underline">클라우드PC 사용 안내</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="space-60"></div>
              <div class="row">
                <div class="col-6">
                  <div class="table-wrap">
                    <UserApply v-if="serverApiRoot && employeeNo && isCheckApply" :userApplyId="userApplyId" ref="UserApply" @StartAuthTimer="StartAuthTimer"  />
                    <div class="space-40"></div>
                    <UserAuthLog v-if="serverApiRoot && employeeNo && sessionId" ref="UserAuthLog" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="call">
                    <h6 class="number">
                      <i class="icon-fux-call"></i>02.2010.1269
                    </h6>
                    <p>
                      접속이 잘 안되시거나 궁금한 사항이 있으시면<br />
                      위의 번호로 문의 부탁드립니다.
                    </p>
                  </div>
                  <div class="banner-wrap">
                    <div class="banner-list">
                      <ul>
                        <li>
                          <a @click="LoadPath('contact')">
                            <i class="icon-fux-interpreter-mode"></i>
                            <span>비상연락망</span>
                          </a>
                        </li>
                        <li>
                          <a @click="LoadPath('bcpmanual')">
                            <i class="icon-fux-book"></i>
                            <span>BCP 매뉴얼</span>
                          </a>
                        </li>
                        <li>
                          <a @click="LoadPath('bcptraining')">
                            <i class="icon-fux-download-done"></i>
                            <span>BCP 훈련</span>
                          </a>
                        </li>
                        <li>
                          <a onclick='window.open("/FAQ");'>
                            <i class="icon-fux-quiz"></i>
                            <span>자주하시는 질문</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <Announcements v-if="serverApiRoot && employeeNo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div class="footer-container">
            <div class="copyright">
              <img
                src="../assets/images/logo_gray.png"
                alt="신한금융지주회사"
              />
              <div class="text">
                클라우드 PC 포털은 임직원을 위한 시스템으로 불법으로 사용시에는
                법적 제제가 있을 수 있습니다.
                <p class="en">
                  COPYRIGHT 2023 BY SHINHAN FINANCIAL GROUP ALL RIGHT RESERVED.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isReturnModal == true" id="modal-return" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">클라우드PC 반납</h4>
              </div>
              <div class="ml-auto">
                <a
                  class="close-btn"
                  @click="isReturnModal = false"
                >
                  <i class="icon-fux-close1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="modal-size optional-modal-padding" style="width: 600px">
              클라우드PC 를 반납하시면 클라우드PC 내부에 저장되었던 자료는 3일
              이내 모두 삭제됩니다.<br />클라우드PC 를 반납하시겠습니까?
            </div>
          </div>
          <div class="modal-footer">
            <div class="align-center">
              <a class="btn" @click="isReturnModal = false">닫기</a>
              <span class="btn-bdr"></span>
              <a class="btn" @click="ReturnVm">반납</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isVpnPassOwnedMedia" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">SSL VPN 2차 인증</h4>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="modal-size optional-modal-padding" style="width:200px;text-align:center;">
                <input type="password" id="vpnVerificationCode" class="fm-def" v-model="vpnVerificationCode" @input="HandleVpnCheckInput" :maxlength="maxDigits" style="width:200px;text-align:center;">
                <div class="space-l "></div>
                <div v-if="showVpnCheckMag">
                  <p v-if="!vpnEnd" class="time">잔여시간 {{ vpnTimer }}</p>
                  <p v-else class="time step-process-txt ft-red">잔여시간 {{ vpnTimer }}</p>
                  <p class="ft-l">인증번호 발송: {{smsSendNumber}}</p>
                  <p class="ft-l">{{vpnCheckMag}}</p>
                </div>
                <div v-if="showVpnCheckErrMag" class="ft-red">{{vpnCheckErrMag}}</div>
            </div>
            <div id="vpnBlankTab" style="display:none"></div>
          </div>
          <div class="modal-footer">
            <div class="align-center">
              <a class="btn" @click="CloseSetVpnCheckModal">닫기</a>
              <span class="btn-bdr"></span>
              <a class="btn" @click="CallCheck" @keyup.enter="CallCheck">확인</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isVpnGuide == true" id="modal-return" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">VPN 연결 가이드</h4>
              </div>
              <div class="ml-auto">
                <a
                  class="close-btn"
                  @click="isVpnGuide = false"
                >
                  <i class="icon-fux-close1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="space-l"></div>
          <div class="modal-content">
            <iframe src="vpnguide_video.html" style="width: 700px; height: 380px;"></iframe>
            <!-- <img src="../assets/images/VpnGuide.gif" /> -->
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import UserApply from "@/components/Sub_UserApply.vue";
import Announcements from "@/components/Sub_Announcements.vue";
import UserAuthLog from "@/components/Sub_UserAuthLog.vue";

export default {
  components: {
    UserApply,
    Announcements,
    UserAuthLog,
  },
  data() {
    return {
      isReturnModal: false,
      isVpnGuide: false,

      loginUser: this.$route.params.currentUserId,
      employeeNo: sessionStorage.getItem("loginUser"),
      authSessionId: this.$route.params.authSessionId,
      sessionId: sessionStorage.getItem("authSessionId"),
      serverApiRoot: sessionStorage.getItem("serverApiRoot"),

      userApplyId: null,
      userInfo: null,
      applyInfo: null,

      isUpdateAuthVpn: false,
      isUpdateAuthVm: false,
      isCheckRemote: false,
      isCheckApply: false,
      isApproved: false,
      isVpnConnected: false,
      isCallCreateVm: false,
      vpnInterval: null,
      vmInterval: null,
      scriptInterval: null,
      vpnInterfaceSuc: true,
      turnOnVm: false,
      vmStatus: null,
      vmCheckCnt: 0,
      vmStatusCheckCnt: 0,
      remoteStatusCheckCnt: 0,
      vpnStep: 0,
      vmStep: 0,
      vpnExpect: 0,
      vmExpect: 0,
      vmRunningCnt: 0,
      checkScriptCount: 0,
      vmCreateFirst: false,
      vpnConnectUrl: null,
      vpnSessionId: null,
      isVpnPassOwnedMedia: false,
      passUserId: null,
      methodType: "SMS",
      eventId: null,
      vpnVerificationCode: "",
      maxDigits: 6,
      showVpnCheckErrMag: false,
      showVpnCheckMag: false,
      vpnCheckErrMag: "",
      vpnCheckMag: "",
      smsAuthProgressCheck: false,
      vmCheckIntervalSize: 1000,

      vpnTimer: "03:00",
      vpnSeconds: 180,
      vpnEnd: false,
      authTimer: null,
      smsSendNumber: "",

      rdpSessionInterval: null,
      rdpSessionCount: null,
      isCheckRdpSession: false,

      loginSessionEnd: null,
    };
  },
  mounted() {
    if (this.serverApiRoot == '' || this.serverApiRoot == null) {
      this.serverApiRoot = `${window.location.protocol}//${window.location.hostname}`;
      const apiUrl = {
        "https://cloudpc.shinhangroup.com": "https://view.cloudpc.shinhangroup.com",
        "https://cpc.shinhangroup.com": "https://view.cpc.shinhangroup.com",
        "http://localhost": "http://localhost:8083",
        "http://10.243.244.133": "http://10.243.244.133:8083",
      };
      this.serverApiRoot = apiUrl[this.serverApiRoot];
      sessionStorage.setItem("serverApiRoot", this.serverApiRoot);    
    }
    //this.vpnConnectUrl = "https://cloudpc.shinhangroup.com:8443/my.policy";
    this.vpnConnectUrl = "https://cloudpc.shinhangroup.com:8443";
    // this.vpnConnectUrl = "https://10.243.244.149:443";
    
    this.MountAction();
  },
  beforeUnmount() {
    this.StopAllInterval();
  },
  methods: {
    SoonAlarm() {
      alert("준비 중 입니다.");
    },
    async MountAction() {
      if (this.loginUser && this.authSessionId) {
        sessionStorage.setItem("loginUser", this.loginUser);
        sessionStorage.setItem("authSessionId", this.authSessionId);
      }

      if (
        sessionStorage.getItem("loginUser") &&
        sessionStorage.getItem("authSessionId")
      ) {
        this.employeeNo = sessionStorage.getItem("loginUser");
        this.sessionId = sessionStorage.getItem("authSessionId");
        this.GetUserInfoItem();
        // await this.DeleteVpnInfo();
        this.GetUserApplyItemFirst();
        // this.GetUserApplyItem();
      } else {
        alert("잘못된 접근입니다.");
        this.$router.push({
          name: "/Login",
        });
      }
    },
    OpenPolicy() {
      this.$nextTick(() => {
        this.$refs.UserApply.OpenPolicy();
      });
    },
    GetUserInfoItem() {
      const data = {
        isCheck: false
      };

      this.$axios
        .post(`${this.serverApiRoot}/getUserInfoItem/`, data, { withCredentials: true })
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            this.userInfo = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetUserApplyItemFirst() {
      this.$axios
        .post(`${this.serverApiRoot}/getUserApplyItem/`, {}, { withCredentials: true })
        .then((response) => {
          this.isCheckApply = true;
          if (response.data) {
            this.userApplyId = response.data._id;
            this.isApproved = true;
            this.SetVpnInfo();
            this.GetVmSessionFirst();
            this.GetUserApplyItemReverse();
          } else {
            this.isApproved = false;
            this.DeleteVpnInfo();
            this.ControlApplyButton(true);
            this.GetUserApplyItem();
            this.StartAuthTimer();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetUserApplyItem() {
      if (this.applyInterval == null) {
        this.applyInterval = setInterval(() => {
          this.$axios
            .post(`${this.serverApiRoot}/getUserApplyItem/`, {}, { withCredentials: true })
            .then((response) => {
              if (response.data) {
                this.StopUserApply();
                this.$router.go(0);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, 10000);
      }
    },
    GetUserApplyItemReverse() {
      if (this.applyInterval == null) {
        this.applyInterval = setInterval(() => {
          this.$axios
            .post(`${this.serverApiRoot}/getUserApplyItem/`, {}, { withCredentials: true })
            .then((response) => {
              if (!response.data) {
                this.StopUserApply();
                this.$router.go(0);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, 10000);
      }
    },    
    StopUserApply() {
      if (this.applyInterval != null) {
        clearInterval(this.applyInterval);
        this.applyInterval = null;
      }
    },
    GetVpnSessionFirst() {
      this.$axios
        .post(`${this.serverApiRoot}/getVpnSession/`, {}, { withCredentials: true })
        .then((response) => {
          let isVpnConnectedInterval = false;
          if (response.data) {
            this.vpnSessionId = response.data;
            isVpnConnectedInterval = true;
          }
          // if (response.data.entries) {
          //   for (const entry in response.data.entries) {
          //     const item = response.data.entries[entry];
          //     if (item.nestedStats.entries.logonUser) {
          //       if (item.nestedStats.entries.logonUser.description == this.employeeNo) {
          //         this.vpnSessionId = entry.toString().split("/").pop();
          //         isVpnConnectedInterval = true;
          //         break;
          //       }
          //     }
          //   }
          // }

          this.isVpnConnected = isVpnConnectedInterval;
          if (this.isVpnConnected) {
            this.vpnStep = 2;
            this.StopAuthTimer();
            if (this.vmStep != -1) {
              this.vmStep = 2;
            }
            // this.ControlApplyButton(false);
            if (this.isUpdateAuthVpn == false) {
              this.UpdateUserAuthLogVpn();
            }
          } else {
            this.vmStep = 1;
            this.vpnStep = 1;
            if (this.authTimerInterval == null) {
              this.StartAuthTimer();
            }
            // this.ControlApplyButton(true);
          }

          this.GetVpnSession();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetVpnSession() {
      if (this.vpnInterval == null) {
        this.vpnInterval = setInterval(() => {
          this.$axios
            .post(`${this.serverApiRoot}/getVpnSession/`, {}, { withCredentials: true })
            .then((response) => {
              let isVpnConnectedInterval = false;
              if (response.data) {
                this.vpnSessionId = response.data;
                isVpnConnectedInterval = true;
              }

              this.isVpnConnected = isVpnConnectedInterval;
              if (this.isVpnConnected) {
                this.vpnStep = 2;
                this.StopAuthTimer();
                if (this.vmStep != -1) {
                  this.vmStep = 2;
                }
                // this.ControlApplyButton(false);
                if (this.isUpdateAuthVpn == false) {
                  this.UpdateUserAuthLogVpn();
                }
                // this.StopVpnSession();
              } else {
                this.vmStep = 1;
                this.vpnStep = 1;
                if (this.authTimerInterval == null) {
                  this.StartAuthTimer();
                }                             
                // this.ControlApplyButton(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, 10000);
      }
    },
    StopVpnSession() {
      if (this.vpnInterval != null) {
        clearInterval(this.vpnInterval);
        this.vpnInterval = null;
      }
    },
    SetVpnInfo() {
      this.$axios
        .post(`${this.serverApiRoot}/setVpnInfo/`, {}, { withCredentials: true })
        .then(() => {
        })
        .catch((error) => {
          this.vpnInterfaceSuc = false;
          this.vpnStep = -1;
          console.log(error);
        });
    },
    DeleteVpnInfo() {
      this.$axios
        .post(`${this.serverApiRoot}/deleteVpnInfo/`, {}, { withCredentials: true })
        .then(() => {
        })
        .catch((error) => {
          this.vpnInterfaceSuc = false;
          console.log(error);
        });
    },
    CallVpnConnectSSO(code) {
      let formData = document.createElement('form');
      formData.method = 'POST';
      formData.action = this.vpnConnectUrl;
      let targetName = 'F5VPN';
      formData.target = targetName;
      //formData.target = '_blank';

      let param1Input = document.createElement('input');
      param1Input.type = 'hidden';
      param1Input.name = 'code';
      param1Input.value = code;
    
      formData.appendChild(param1Input);
      let vpnPostDiv = document.getElementById("vpnBlankTab");
      vpnPostDiv.appendChild(formData);
    
      formData.addEventListener('submit', function(event) {
        event.preventDefault();
        vpnPostDiv.removeChild(formData);
      });
      formData.submit();
    },
    ReturnVm() {
      const data = {
        id: this.userApplyId,
      };

      this.$axios
        .post(`${this.serverApiRoot}/returnUserApply/`, data, { withCredentials: true })
        .then((response) => {
          if (response.data.id) {
            if (this.vpnSessionId) {
              this.DeleteVpnSession();
            }
            this.StopVm();
            this.$router.go(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    DeleteVpnSession() {
      const data = {
        vpnSessionId: this.vpnSessionId,
      };

      this.$axios
        .post(`${this.serverApiRoot}/deleteVpnSession/`, data, { withCredentials: true })
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },    
    StartVm() {
      this.turnOnVm = true;

      this.$axios
        .post(`${this.serverApiRoot}/startVm/`, {}, { withCredentials: true })
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    StopVm() {
      this.$axios
        .post(`${this.serverApiRoot}/stopVm/`, {}, { withCredentials: true })
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    CreateVm() {
      console.log("in createVm function");
      this.isCallCreateVm = true;
      
      this.$axios
        .post(`${this.serverApiRoot}/createVm/`, {}, { withCredentials: true })
        .then((response) => {
          console.log("createVm");
          console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    GetVmSessionFirst() {
      this.$axios
        .post(`${this.serverApiRoot}/getVmSession/`, {}, { withCredentials: true })
        .then((response) => {
          if (response.data) {
            const data = JSON.parse(response.data);
            if (data.code == "PowerState/running") {
              this.vmStatusCheckCnt = 0;
              this.GetScriptLogItemFirst();
            } else {
              this.GetVmSession();
            }
          } else {
            this.GetVmSession();
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.vmCheckCnt++;
    },
    GetVmSession() {
      if (this.vmInterval == null) {
        this.vmInterval = setInterval(() => {
          this.$axios
            .post(`${this.serverApiRoot}/getVmSession/`, {}, { withCredentials: true })
            .then((response) => {
              if (response.data) {
                const data = JSON.parse(response.data);
                if (data.code == "PowerState/running") {
                  this.vmStatusCheckCnt = 0;
                  this.GetScriptLogItemFirst();
                  this.StopVmSession();
                } else {
                  if (this.isCallCreateVm == false && this.turnOnVm == false) {
                    this.StartVm();
                  }

                  if (this.vmStatusCheckCnt == 60) {
                    this.vmStep = -2;
                    this.StopVmSession();
                  }

                  this.vmStatusCheckCnt++;
                }
              } else {
                if (this.isCallCreateVm == false && this.vmCheckCnt >= 1) {
                  this.vmExpect = 10;
                  this.vpnExpect = 10;
                  this.CreateVm();
                }
                
                if (this.vmCheckCnt == 60) {
                  this.vmStatus = "";
                  this.vmStep = -1;
                  this.vpnStep = -1;
                  this.StopVmSession();
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });

          this.vmCheckCnt++;
        }, 10000);
      }
    },
    StopVmSession() {
      if (this.vmInterval != null) {
        clearInterval(this.vmInterval);
        this.vmInterval = null;
      }
    },
    GetScriptLogItemFirst() {
      this.$axios
        .post(`${this.serverApiRoot}/getScriptLogItemByEmployeeNo/`, {}, { withCredentials: true })
        .then((response) => {
          if (response.data) {
            const data = response.data;
            if (data.createVmEnd != null && data.addLocalUserEnd != null && data.setMsOfficeEnd != null) {
              if (data.addLocalUserResult == true && data.setMsOfficeResult == true) {
                this.vpnExpect = 1;
                this.vmExpect = 1;  
                this.vmStatus = "활성";
                this.StartAuthTimer();
                // this.GetRdpSession();
                this.ChangeApplyStatus();
                if (this.vmStep == 0) {
                  this.vmStep = 1;
                }
                
                if (this.vmCreateFirst == false) {
                  this.vmCreateFirst = true;
                  if (this.vpnInterfaceSuc) {
                    this.vpnStep = 1;
                  }
                  this.GetVpnSessionFirst();
                }
              } else {
                this.vmStatus = "";
                this.vmStep = -2;
                this.vpnStep = -1;       
              }
            } else {
              if (data.createVmEnd != null) {
                this.vpnExpect = 5;
                this.vmExpect = 5;
              } else {
                this.vpnExpect = 10;
                this.vmExpect = 10;
              }
              this.GetScriptLogItem(data._id);
            }
          } else {
            this.vpnExpect = 1;
            this.vmExpect = 1;              
            this.GetRemoteUserSession();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetScriptLogItem(id) {  
      const data = {
        id: id,
      };
      this.checkScriptCount = 0;
      if (this.scriptInterval == null) {
        this.scriptInterval = setInterval(() => {
          if (this.checkScriptCount > 60) {
            this.vmStatus = "";
            this.vmStep = -2;
            this.vpnStep = -1;   
            this.StopScriptLog();              
            return;
          }
          this.$axios
            .post(`${this.serverApiRoot}/getScriptLogItemById/`, data, { withCredentials: true })
            .then((response) => {
              if (response.data) {
                const data = response.data;
                if (data.createVmEnd != null && data.addLocalUserEnd != null && data.setMsOfficeEnd != null) {
                  if (data.addLocalUserResult == true && data.setMsOfficeResult == true) {
                    this.vmStatus = "활성";
                    this.StartAuthTimer();
                    // this.GetRdpSession();
                    this.ChangeApplyStatus();
                    if (this.vmStep == 0) {
                      this.vmStep = 1;
                    }
                    
                    if (this.vmCreateFirst == false) {
                      this.vmCreateFirst = true;
                      if (this.vpnInterfaceSuc) {
                        this.vpnStep = 1;
                      }
                      this.GetVpnSessionFirst();
                    }
                    this.StopScriptLog();
                  } else {
                    this.vmStatus = "";
                    this.vmStep = -2;
                    this.vpnStep = -1;   
                    this.StopScriptLog();    
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
            this.checkScriptCount++;
        }, 10000);
      }
    },  
    StopScriptLog() {
      if (this.scriptInterval != null) {
        clearInterval(this.scriptInterval);
        this.scriptInterval = null;
      }
    },    
    GetRemoteUserSession() {
      this.isCheckRemote = true;    

      if (this.remoteStatusCheckCnt == 5) {
        this.vmStatus = "";
        this.vmStep = -2;
        this.vpnStep = -1;        
        return;
      }

      this.$axios
        .post(`${this.serverApiRoot}/getRemoteUserSession/`, {}, { withCredentials: true })
        .then((response) => {
          if (response.data != null && response.data != '') {
            const data = response.data.split('\n').map((line) => line.trim());
            let isRegi = false;
            for (let i = 0; i < data.length; i++) {
              if (data[i].replace(/\s/g, '') == "Message:Y") {
                isRegi = true;
                break;
              }
            }
            
            if (isRegi) {
              this.vmStatus = "활성";
              this.StartAuthTimer();
              // this.GetRdpSession();
              if (this.vmStep == 0) {
                this.vmStep = 1;
              }
              
              if (this.vmCreateFirst == false) {
                this.vmCreateFirst = true;
                if (this.vpnInterfaceSuc) {
                  this.vpnStep = 1;
                }
                this.GetVpnSessionFirst();
              }
            } else {
              this.remoteStatusCheckCnt++;
              this.GetRemoteUserSession();             
            }
          } else {
            this.GetRemoteUserSession();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetRdpSession() {
      this.isCheckRdpSession = true;


      // 테스트용
      // this.rdpSessionCount = null;

      this.$axios
        .post(`${this.serverApiRoot}/getRdpSession/`, {}, { withCredentials: true })
        .then((response) => {
          this.rdpSessionCount = response.data;

          this.StopRdpSession();
          this.rdpSessionInterval = setTimeout(() => {
            this.GetRdpSession();
          }, 10000);
        })
        .catch((error) => {
          console.log(error);
          this.rdpSessionCount = 0;
        });
    },
    StopRdpSession() {
      clearInterval(this.rdpSessionInterval);
      this.rdpSessionInterval = null;
    },
    GetRdpFile() {
      this.$axios
        .post(`${this.serverApiRoot}/getRdpFile/`, {}, {
          responseType: "blob",
          withCredentials: true
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.employeeNo}.rdp`);
          document.body.appendChild(link);
          link.click();
          if (this.isUpdateAuthVm == false) {
            this.UpdateUserAuthLogVm();
          }
          // this.vmStep = 3;
        })
        .catch((error) => {
          console.log(error);
        });

      this.StartAuthTimer();
    },
    UpdateUserAuthLogVpn() {
      const data = {
        id: this.sessionId,
      };
      
      this.$axios
        .post(`${this.serverApiRoot}/updateUserAuthLogVpn/`, data, { withCredentials: true })
        .then((response) => {
          if (response) {
            this.isUpdateAuthVpn = true;
            this.GetUserAuthLogList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },    
    UpdateUserAuthLogVm() {
      const data = {
        id: this.sessionId,
      };

      this.$axios
        .post(`${this.serverApiRoot}/updateUserAuthLogVm/`, data, { withCredentials: true })
        .then((response) => {
          if (response) {
            this.isUpdateAuthVm = true;
            this.GetUserAuthLogList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },      
    ChangeApplyStatus() {
      this.$nextTick(() => {
        this.$refs.UserApply.ChangeApplyStatus();
      });      
    },
    ControlApplyButton(flag) {
      this.$nextTick(() => {
        this.$refs.UserApply.ControlApplyButton(flag);
      });      
    },
    GetUserAuthLogList() {
      this.$nextTick(() => {
        this.$refs.UserAuthLog.GetUserAuthLogList();
      });      
    },    
    LoadPath(key) {
      const data = {
        key: key
      }
      this.$axios
        .post(`${this.serverApiRoot}/getConfigResource/`, data, { responseType: 'arraybuffer', withCredentials: true })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.target = '_blank';
          link.click();
          //alert('준비 중 입니다.');
        })
        .catch(() => {
          alert("(클라우드PC 베타 오픈) 준비 중입니다.");
        });
    },
    Logout() {
      sessionStorage.clear();
      this.StopAllInterval();

      this.$axios
        .get(`${this.serverApiRoot}/logout/`, { withCredentials: true })  
        .then((response) => {
          if (response.status == 200 && response.data == 'Succese' && response.data != null) {
            sessionStorage.clear();
            this.$router.push({
              name: "/Login",
            });
          } else {
            alert('로그아웃 실패');
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    StopAllInterval() {
      this.StopUserApply();
      this.StopVmSession();
      this.StopScriptLog();
      this.StopVpnSession();
      this.StopVpnTimer();
      this.StopAuthTimer();
      this.StopRdpSession();
    },
    HandleVpnCheckInput() {
      this.showVpnCheckErrMag = false;
      this.vpnCheckErrMag = "";
    },
    IsValidateCode() {
      const isNumericCode = /^\d{6}$/.test(this.vpnVerificationCode);

      if (isNumericCode) {
        this.vpnCheckErrMag = "";
        this.showVpnCheckErrMag = false;
        return true;
      } else {
        this.vpnCheckErrMag = '올바른 형식의 6자리 숫자를 입력하세요.';
        this.showVpnCheckErrMag = true;
        return false;
      }
    },
    OpenSetVpnCheckModal () {
      this.vpnVerificationCode = '';
      this.isVpnPassOwnedMedia = true;
      this.showVpnCheckMag = true;
      this.vpnCheckMag = 'SMS 인증 번호가 발송 됐습니다.';
      this.vpnTimer = '03:00';
      this.vpnSeconds = 180;
      this.smsSendNumber = '';
      this.CallVpnAuthorize();
      this.StartAuthTimer();
    },
    StartVpnTimer() {
      this.vpnTimerInterval = setInterval(() => {
        this.vpnSeconds--;
        if (this.vpnSeconds >= 0) {
          this.vpnTimer = this.FormatTime(this.vpnSeconds);
        } else {
          this.vpnTimer = "00:00";
          this.vpnEnd = true;
          this.CloseSetVpnCheckModal();
          this.StopVpnTimer();
        }
      }, 1000);
    },
    StartAuthTimer() {
      this.StopAuthTimer();

      let authStart = new Date();
      // interval 시작 시간 때문에 1초 추가
      this.loginSessionEnd = new Date(authStart.getTime() + (5 * 60 * 1000) + 1);
      this.authTimer = "05:00";
      this.authTimerInterval = setInterval(() => {
        const authTime = new Date();
        const timeDifference = (this.loginSessionEnd.getTime() - authTime.getTime()) / 1000;
        if (timeDifference >= 0) {
          this.authTimer = this.FormatTime(timeDifference);
        } else {
          this.authTimer = "00:00";
          this.Logout();
        }        
        // this.timerSeconds--;
        // if (this.timerSeconds >= 0) {
        //   this.authTimer = this.FormatTime(this.timerSeconds);
        // } else {
        //   this.authTimer = "00:00";
        //   this.authEnd = true;
        //   this.Logout();
        // }
      }, 1000);
    },    
    FormatTime(seconds) {
      seconds = Math.floor(seconds);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const minutesString = String(minutes).padStart(2, "0");
      const secondsString = String(remainingSeconds).padStart(2, "0");
      return `${minutesString}:${secondsString}`;
    },
    CallVpnAuthorize() {
      const data = {
        authRequestMethod: this.methodType,
        isCheck: false
      };

      this.$axios
        .post(`${this.serverApiRoot}/authorize_ownedmedia/`, data, { withCredentials: true })
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            if (response.data.common.resultCode == 200) {
              if (response.data.data.authEventId) {
                this.isVpnPassOwnedMedia = true;
                this.passUserId = data.userId;
                this.eventId = response.data.data.authEventId;
                this.smsSendNumber = response.data.data.cellPhoneNumber
                this.StartVpnTimer();
              } else {
                alert("인증 ID를 찾을 수 없습니다.");
                //this.CloseSetVpnCheckModal();
              }
            } else {
              alert(response.data.common.errorMessage);
              //this.CloseSetVpnCheckModal();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.CloseSetVpnCheckModal();
        });
    },
    CallCheck() {
      if (this.IsValidateCode()) {
        const data = {
          eventId: this.eventId,
          randomNumber: this.vpnVerificationCode,
          isCheck: false
        };

        if (this.smsAuthProgressCheck) {
          alert('SMS 인증 중입니다. 잠시 기다려주세요.');
          return false;
        }

        this.smsAuthProgressCheck = true;
        this.$axios
          .post(`${this.serverApiRoot}/check_auth/`, data, { withCredentials: true })
          .then((response) => {
            if (response.status == 200 && response.data != null) {
              if (response.data.common.resultCode == 200) {
                this.CloseSetVpnCheckModal();
                if (response.data.data.code) {
                  this.CallVpnConnectSSO(response.data.data.code);
                } else {
                  alert('인증 코드 확인 불가');
                }
              } else {
                alert(response.data.common.errorMessage);
                this.CloseSetVpnCheckModal();
              }
            }
            this.smsAuthProgressCheck = false;
          })
          .catch((error) => {
            this.smsAuthProgressCheck = false;
            console.log(error);
            this.CloseSetVpnCheckModal();
          });
      }
    },
    CloseSetVpnCheckModal () {
      this.isVpnPassOwnedMedia = false;
      this.showVpnCheckMag = false;
      this.vpnCheckMag = ""
      this.StopVpnTimer();
    },
    StopVpnTimer() {
      if (this.vpnTimerInterval != null) {
        clearInterval(this.vpnTimerInterval);
        this.vpnTimerInterval = null;
      }
    },
    StopAuthTimer() {
      this.authTimer = null;
      if (this.authTimerInterval != null) {
        clearInterval(this.authTimerInterval);
        this.authTimerInterval = null;
      }
    },    
    CallVpnGuide() {
      this.isVpnGuide = true;
    }
  },
};
</script>
