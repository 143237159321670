<template>
  <div>
    <VueDatePicker v-model="date" :format="DateFormat(date)" text-input>
      <template #time-picker="{ time, updateTime }">
        <div class="custom-time-picker-component">
          <select
            class="select-input"
            :value="time.hours"
            @change="updateTime(+$event.target.value)"
          >
            <option v-for="h in hoursArray()" :key="h.value" :value="h.value">
              {{ h.text }}
            </option>
          </select>
          <select
            class="select-input"
            :value="time.minutes"
            @change="updateTime(+$event.target.value, false)"
          >
            <option v-for="m in minutesArray()" :key="m.value" :value="m.value">
              {{ m.text }}
            </option>
          </select>
        </div>
      </template>
    </VueDatePicker>
    <VueDatePicker v-model="date2" text-input/>
    <button @click="AlertDate">dd</button>
  </div>
</template>
<script>
import { format } from "date-fns";

export default {
  components: {},
  data() {
    return {
      date: new Date(),
      date2: new Date(),
      format: null,
    };
  },
  mounted() {},
  beforeUnmount() {},
  methods: {
    DateFormat(date) {
      return format(date, "yyyy-MM-dd HH:mm");
    },
    AlertDate() {
      alert(this.date);
    },
    hoursArray() {
        const arr = [];
        for (let i = 0; i < 24; i++) {
            arr.push({ text: i < 10 ? `0${i}` : i, value: i });
        }
        return arr;
    },
    minutesArray() {
    const arr = [];
        for (let i = 0; i < 60; i++) {
            arr.push({ text: i < 10 ? `0${i}` : i, value: i });
        }
        return arr;
    }
  }
}
</script>
