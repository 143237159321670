<template>
  <div>
    <div class="dp-flex">
      <div class="mr-auto">
        <h6 class="title" @click="OpenList">
          <a>접속이력 <i class="icon-fux-page-next"></i></a>
        </h6>
      </div>
      <div class="ml-auto">
        <p class="notify">
          의심스러운 접속이 있나요? <a @click="SoonAlarm">여기</a>를 눌러
          신고하세요.
        </p>
      </div>
    </div>
    <div class="space-s"></div>
    <div class="grd-wrap">
      <table width="100%" class="grd">
        <colgroup>
          <col />
          <!-- <col /> -->
          <col />
          <!-- <col /> -->
        </colgroup>
        <thead>
          <tr>
            <th>포털 로그인</th>
            <!-- <th>접속IP주소</th> -->
            <th>VPN 연결</th>
            <!-- <th>VM 연결</th> -->
          </tr>
        </thead>
        <tbody v-if="authInfo != 0 && doneList == true">
          <tr v-for="auth in authInfo" :key="auth._id">
            <td class="align-center">
              {{ auth.createDate.replace("T", " ").slice(0, -7) }}
            </td>
            <!-- <td class="align-center">
              {{ auth.userIp }}
            </td> -->
            <td class="align-center">
              <span v-if="auth.vpnTime != null">{{ auth.vpnTime.replace("T", " ").slice(0, -7) }}</span>
            </td>
            <!-- <td class="align-center">
              <span v-if="auth.vmTime != null">{{ auth.vmTime.replace("T", " ").slice(0, -7) }}</span>
            </td> -->
          </tr>
        </tbody>
        <tbody v-if="authInfo == 0 && doneList == true">
          <tr>
            <td class="align-center" :colspan="4">
              접속 이력이 없습니다.
            </td>
          </tr>
        </tbody>          
      </table>
    </div>
    <div v-if="isAuthModal" class="modal">
      <div class="modal-flex">
        <div class="modal-flex-inner">
          <div class="modal-header">
            <div class="dp-flex">
              <div class="mr-auto">
                <h4 class="title">접속 이력 조회</h4>
              </div>
              <div class="ml-auto">
                <a class="close-btn" @click="CloseList">
                  <i class="icon-fux-close1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="modal-size optional-modal-padding" style="width: 600px">
              <div class="around-scroll-grd" style="max-height: 280px">
                <table width="100%" class="grd">
                  <colgroup>
                    <col />
                    <!-- <col /> -->
                    <col />
                    <!-- <col /> -->
                  </colgroup>
                  <thead>
                    <tr>
                      <th>포털 로그인</th>
                      <!-- <th>접속IP주소</th> -->
                      <th>VPN 연결</th>
                      <!-- <th>VM 연결</th> -->
                    </tr>
                  </thead>
                  <tbody v-if="authAllInfo.length != 0 && doneList == true">
                    <tr v-for="auth in authAllInfo" :key="auth._id">
                      <td class="align-center">
                        {{ auth.createDate.replace("T", " ").slice(0, -7) }}
                      </td>
                      <!-- <td class="align-center">
                        {{ auth.userIp }}
                      </td> -->
                      <td class="align-center">
                        <span v-if="auth.vpnTime != null">{{ auth.vpnTime.replace("T", " ").slice(0, -7) }}</span>
                      </td>
                      <!-- <td class="align-center">
                        <span v-if="auth.vmTime != null">{{ auth.vmTime.replace("T", " ").slice(0, -7) }}</span>
                      </td> -->
                    </tr>
                  </tbody>
                  <tbody v-if="authAllInfo.length == 0 && doneList == true">
                    <tr>
                      <td class="align-center" :colspan="4">
                        접속 이력이 없습니다.
                      </td>
                    </tr>
                  </tbody>                 
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <div class="align-center">
              <a class="btn" @click="CloseList">닫기</a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      doneList: false,      
      employeeNo: sessionStorage.getItem("loginUser"),
      sessionId: sessionStorage.getItem("authSessionId"),
      authInfo: [],
      authAllInfo: [],
      isAuthModal: false,
      serverApiRoot: sessionStorage.getItem("serverApiRoot"),
    };
  },
  mounted() {
    this.GetUserAuthLogList();
  },
  methods: {
    SoonAlarm() {
      alert("준비 중 입니다.");
    },
    GetUserAuthLogList() {
      const data = {
        id: this.sessionId,
        limit: 0,
      };

      this.authInfo = [];
      this.$axios
        .post(`${this.serverApiRoot}/getUserAuthLogList/`, data, { withCredentials: true })
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            this.authAllInfo = response.data;
            let maxCnt = this.authAllInfo.length >= 4 ? 4 : this.authAllInfo.length;
            for (let i = 0; i <= maxCnt - 1; i++) {
              this.authInfo.push(this.authAllInfo[i]);
            }            
            this.doneList = true;
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    OpenList() {
      this.isAuthModal = true;
    },
    CloseList() {
      this.isAuthModal = false;
    },
  },
};
</script>