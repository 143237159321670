<template>
    <div v-if="this.employeeNo" id="common-wrapper">
        <div id="wrapper">
            <div id="header">
                <div class="header-container">
                    <div class="header-item align-left">
                        <h1 class="logo">
                            <a href="/Main">
                                <img src="../assets/images/logo_blue.png" alt="신한금융지주회사">
                                <span>클라우드PC</span>
                            </a>
                        </h1>
                    </div>
                    <div class="header-item align-center">
                        <nav class="user-nav">
                            <ul>
                                <li @click="$router.push('/Notice')">
                                    <a>공지사항</a>
                                </li>
                                <li class="on">
                                    <a>자주하시는 질문</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="header-item align-right"></div>
                </div>
            </div>
            <div id="outer-wrap">
                <div id="inner-wrap">
                    <div class="main-wrap">
                        <div class="user-header">
                            <h3 class="title">자주하시는 질문</h3>
                        </div>
                        <div class="user-body">
                            <div class="space-50"></div>
                            <div class="faq-page-area">
                                <ul class="faq-tab-header">
                                    <li :class="selectedTab == '' ? 'active' : ''" @click="ClickTab('')">
                                        <a data-toggle="tab" >전체</a>
                                    </li>
                                    <li :class="selectedTab == '0' ? 'active' : ''" @click="ClickTab('0')">
                                        <a data-toggle="tab">인증</a>
                                    </li>
                                    <li :class="selectedTab == '1' ? 'active' : ''" @click="ClickTab('1')">
                                        <a data-toggle="tab">사용신청</a>
                                    </li>
                                    <li :class="selectedTab == '2' ? 'active' : ''" @click="ClickTab('2')">
                                        <a data-toggle="tab">오류</a>
                                    </li>
                                </ul>
                                <div class="space-50"></div>
                                <div class="faq-tab-content">
                                    <div class="tab-pane active">
                                        <ul class="faq-list" v-if="faqList === null || faqList.length">
                                            <li v-for="faq in faqList" :key="faq._id" :class="faq.onType ? 'on' : ''" @click="QuestionClick(faq)">
                                                <div class="dp-flex flex-between ">
                                                    <div class="questions">
                                                        <span class="q-icon">Q</span>
                                                        <p v-html="faq.question"></p>
                                                    </div>
                                                </div>
                                                <div class="asked">
                                                    <span class="a-icon">A</span>
                                                    <p v-html="faq.answer"></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- footer -->
            <div id="footer">
                <div class="footer-container">
                    <div class="copyright">
                        <img src="../assets/images/logo_gray.png" alt="신한금융지주회사">
                        <div class="text">
                            클라우드 PC 포털은 임직원을 위한 시스템으로 불법으로 사용시에는 법적 제제가 있을 수 있습니다.
                            <p class="en">COPYRIGHT 2023 BY SHINHAN FINANCIAL GROUP ALL RIGHT RESERVED.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //footer -->
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        employeeNo: sessionStorage.getItem("loginUser"),
        selectedTab: '',
        faqList: null,
        serverApiRoot: sessionStorage.getItem("serverApiRoot"),
    };
  },
  mounted() {
    if (this.employeeNo == '' || this.employeeNo == null) {
        alert('잘못된 접근입니다.');
        return;
    } else {
        this.GetFAQList();
    }
  },
  methods: {
      GetFAQList () {
          const data = {
              subject: this.selectedTab
          }
          this.$axios
              .post(`${this.serverApiRoot}/getFAQList/`, data, { withCredentials: true })
              .then((response) => {
                  if (response.status == 200 && response.data != null) {
                      this.faqList = response.data;
                  }
              })
              .catch((error) => {
                  alert(error);
              });
      },
      ClickTab (tab) {
            this.selectedTab = tab;
            this.GetFAQList();
      },
      QuestionClick(faq) {
            faq.onType = !faq.onType;
      }
  },
};
</script>