import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router/index.js';
import './assets/css/style.css';
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

axios.defaults.timeout = 1200000;

axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        router.push({ name: '/Login' });
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    }
);

const app = createApp(App)
app.component('VueDatePicker', VueDatePicker)
app.config.globalProperties.$axios = axios
app.use(router)
app.mount('#app')