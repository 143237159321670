import { createRouter, createWebHistory } from 'vue-router'

import Login from '../components/LoginComponent'
import Main from '../components/MainComponent'
// import MainTest from '../components/MainTestComponent'
import NoticeList from '../components/NoticeListComponent'
import NoticeItem from '../components/NoticeItemComponent'
import Faq from '../components/FaqComponent'
import Test from '../components/TestComponent'

const routes = [
    {
        name: '/',
        path: '/',
        component: Login
    },
    {
        name: '/Login',
        path: '/Login',
        component: Login
    },
    {
        name: '/Main',
        path: '/Main',
        component: Main
    },
    {
        name: '/Test',
        path: '/Test',
        component: Test
    },
    {
        name: '/Notice',
        path: '/Notice',
        component: NoticeList
    },
    {
        name: '/NoticeItem',
        path: '/NoticeItem',
        component: NoticeItem
    },
    {
        name: '/FAQ',
        path: '/FAQ',
        component: Faq
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export {router}