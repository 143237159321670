<template>
    <div v-if="this.employeeNo" id="common-wrapper">
        <div id="wrapper">
            <div id="header">
                <div class="header-container">
                    <div class="header-item align-left">
                        <h1 class="logo">
                            <a href="/Main">
                                <img src="../assets/images/logo_blue.png" alt="신한금융지주회사">
                                <span>클라우드PC</span>
                            </a>
                        </h1>
                    </div>
                    <div class="header-item align-center">
                        <nav class="user-nav">
                            <ul>
                                <li class="on" @click="$router.push('/Notice')">
                                    <a>공지사항</a>
                                </li>
                                <li @click="$router.push('/FAQ')">
                                    <a>자주하시는 질문</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="header-item align-right"></div>
                </div>
            </div>
            <div id="outer-wrap">
                <div id="inner-wrap">
                    <div class="main-wrap">
                        <div class="user-header">
                            <h3 class="title">{{announcements.title}}</h3>
                        </div>
                        <div class="user-body">
                            <div class="space-s"></div>
                            <div class="notice-read">
                                <p v-html="announcements.content"></p>
                            </div>
                            <div class="space-30"></div>
                            <div class="align-center">
                                <button class="btn page-btn btn-basic" @click = "$router.push('/Notice')">목록</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- footer -->
            <div id="footer">
                <div class="footer-container">
                    <div class="copyright">
                        <img src="../assets/images/logo_gray.png" alt="신한금융지주회사">
                        <div class="text">
                            클라우드 PC 포털은 임직원을 위한 시스템으로 불법으로 사용시에는 법적 제제가 있을 수 있습니다.
                            <p class="en">COPYRIGHT 2023 BY SHINHAN FINANCIAL GROUP ALL RIGHT RESERVED.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //footer -->
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        employeeNo: sessionStorage.getItem("loginUser"),
        announce: null,
        announcements: [],
        serverApiRoot: sessionStorage.getItem("serverApiRoot"),
    };
  },
  mounted() {
    if (this.employeeNo == '' || this.employeeNo == null) {
        alert('잘못된 접근입니다.');
        return;
    } else {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const announce = urlParams.get('id');
        if (announce) {
            this.announce = announce;
            this.GetAnnouncementsItem();
        }
    }
  },
  methods: {
      GetAnnouncementsItem () {
          const data = {
              _id: this.announce
          }
          this.$axios
              .post(`${this.serverApiRoot}/getAnnouncementsItem/`, data, { withCredentials: true })
              .then((response) => {
                  if (response.status == 200 && response.data != null) {
                      this.announcements = response.data;
                  }
              })
              .catch((error) => {
                  alert(error);
              });
      }
  }
};
</script>